
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getPost } from '@/services/api';

import { PartialPost, PostStatus } from '@/interfaces/Post';
import { useI18n } from 'vue-i18n';


const DEFAULT_FORM_VALUES: PartialPost & {} = {
  title: '',
  cover: '',
  coverLink: '',
  isSticky: 0,
  modelIds: [],
  models: [],
  status: PostStatus.PUBLISHED,
  publishedAt: '',
  createdAt: ''
};

export default defineComponent({
  setup() {
    // const data = ref<PartialPost>({});

    const postId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref(DEFAULT_FORM_VALUES);
    const { t } = useI18n();


    onMounted(async() => {
      const res = await getPost({ postId });
      data.value = res.data;
    });

    return {
      data,
      t,
      page
    };
  }
});
